import { Token } from '@uniswap/sdk-core'
import { FeeAmount } from '@uniswap/v3-sdk'
import { WBTC_TOKEN } from './libs/constants'

// Sets if the example should run locally or on chain
export enum Environment {
  MAINNET,
}

// Inputs that configure this example to run
export interface ExampleConfig {
  env: Environment
  rpc: {
    mainnet: string
  }
  pool: {
    tokenA: Token
    tokenB: Token
    fee: FeeAmount
  }
  chart: {
    numSurroundingTicks: number
  }
}

// Example Configuration

export const CurrentConfig: ExampleConfig = {
  env: Environment.MAINNET,
  rpc: {
    mainnet: 'https://rpc.unlock-protocol.com/56',
  },
  pool: {
    tokenA: new Token(56, '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',18, 'BTCB', 'BTCB'),
    tokenB: new Token(56, '0xdCD1AeB176ECd6f8d4A3258413705651eb226e5E',18, 'mmBTC','mmBTC'),
    
    fee: 3000,
  },
  chart: {
    numSurroundingTicks: 50,
  },
}
